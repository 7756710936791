<template>
  <div class="wrapper">
    <div class="header" v-show="!registeredActive">
      绑定读者证
    </div>
    <div class="header" v-show="registeredActive">
      注册读者证
    </div>
    <!--    注册读者证-->
    <div class="phone" v-show="registeredActive">
      <div class="phone-inp" @click="showPhoneKeyBoard = true">{{ phoneNumber }}</div>
      <div class="destroy" v-show="phoneNumber.length && phoneNumber !=='请输入手机号'" @click="phoneNumber = '请输入手机号'">
      </div>
    </div>
    <div class="verify" v-show="registeredActive">
      <div class="verify-inp" @click="showVerifyKeyBoard=true">{{ verifyCode }}</div>
      <div class="get-verify" v-show="!getVerifyFlag" @click="getVerify">
        <div class="get-verify-text">获取验证码</div>
      </div>
      <div class="get-verify-time" v-show="getVerifyFlag">
        <div class="get-verify-time-text">{{ verifyTime }} S</div>
      </div>
    </div>
    <div class="phone" v-show="registeredActive">
      <div class="phone-inp">
        <input type="text" class="inp" placeholder="请输入用户姓名" v-model="userRealName" @blur="kickBack">
      </div>
      <div class="destroy" v-show="userRealName !== ''" @click="userRealName = ''">
      </div>
    </div>
    <div class="phone" v-show="registeredActive">
      <div class="phone-inp" @click="showIdCardKeyBoard = true">{{ idCard }}</div>
      <div class="destroy" v-show="idCard.length && idCard !=='请输入身份证号'" @click="idCard = '请输入身份证号'">
      </div>
    </div>
    <!--    注册读者证结束-->
    <!--    绑定读者证-->
    <div class="phone" v-show="!registeredActive">
      <div class="phone-inp">
        <input type="text" class="inp" placeholder="请输入读者证号/身份证" v-model="userReadCardNo" @blur="kickBack">
      </div>
      <div class="destroy" v-show="userReadCardNo !== ''" @click="userReadCardNo = ''">
      </div>
    </div>
    <div class="phone" v-show="!registeredActive">
      <div class="phone-inp">
        <input type="password" class="inp" placeholder="请输入读者证密码,默认身份证后六位" v-model="userReadCardPass" @blur="kickBack">
      </div>
      <div class="destroy" v-show="userReadCardPass !== ''" @click="userReadCardPass = ''">
      </div>
    </div>
    <!--    绑定读者证结束-->
    <div class="login-desc" @click="readActive = !readActive">
      <div class="login-desc-icon" :class="{active: readActive}"></div>
      <div class="login-desc-text">已阅读
        <router-link tag="span" to="/login/user-agreement" class="warning">《用户协议》</router-link>
        和
        <router-link tag="span" to="/login/privacy-agreement" class="warning">《隐私协议》</router-link>
      </div>
    </div>
    <!--    <div class="login-desc" @click="registeredActive = !registeredActive">-->
    <!--      <div class="login-desc-icon" :class="{active: registeredActive}"></div>-->
    <!--      <div class="login-desc-text">注册读者证</div>-->
    <!--    </div>-->
    <div class="login" v-show="registeredActive" @click="registered" :class="{active:loginActive}">
      <div class="login-text">注册</div>
    </div>
    <div class="login" v-show="!registeredActive" @click="bindUserCard" :class="{active:loginActive}">
      <div class="login-text">绑定</div>
    </div>
    <div class="login" @click="registeredActive = !registeredActive" :class="{active:loginActive}">
      <div class="login-text" v-show="!registeredActive">注册读者证</div>
      <div class="login-text" v-show="registeredActive">绑定内图读者证</div>
    </div>
    <!--手机数字键盘-->
    <number-keyboard
      :show="showPhoneKeyBoard"
      v-model="phoneNumber"
      maxlength="11"
      get-container="#app"
      @blur="showPhoneKeyBoard = false"
    />
    <!--验证码数字键盘-->
    <number-keyboard
      :show="showVerifyKeyBoard"
      v-model="verifyCode"
      maxlength="6"
      get-container="#app"
      @blur="showVerifyKeyBoard = false"
    />
    <!--身份证键盘-->
    <number-keyboard
      :show="showIdCardKeyBoard"
      v-model="idCard"
      maxlength="18"
      extra-key="X"
      get-container="#app"
      @blur="showIdCardKeyBoard = false"
    />
  </div>
</template>

<script>
import { NumberKeyboard, Toast } from 'vant'
import { sendSms } from '@/api/Sms'
import { bindReadCard, registeredReadCard } from '@/api/ReadCard'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'LoginContent',
  computed: {
    ...mapGetters([
      'userGuid'
    ])
  },
  components: {
    NumberKeyboard
  },
  data () {
    return {
      showPhoneKeyBoard: false, // 手机号数字键盘
      phoneNumber: '请输入手机号', // 用户手机号
      showVerifyKeyBoard: false, // 验证码数字键盘
      verifyCode: '请输入验证码', // 用户验证码
      getVerifyFlag: false, // 获取验证码标记
      verifyTime: 60, // 验证码倒计时
      userRealName: '',
      showIdCardKeyBoard: false, // 身份证键盘
      idCard: '请输入身份证号', // 身份证号
      readActive: false, // 服务条款
      registeredActive: true, // 注册
      loginActive: false, // 登录选中
      userReadCardNo: '', // 读者证号
      userReadCardPass: '' // 读者证密码
    }
  },
  methods: {
    kickBack () {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollTop + 1)
        document.body.scrollTop >= 1 && window.scrollTo(0, document.body.scrollTop - 1)
      }, 10)
    },
    getVerify () {
      if (this.getVerifyFlag) {
        return false
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      if (this.phoneNumber === '请输入手机号' || this.phoneNumber === '') {
        Toast.fail('请输入手机号')
        this.loginActive = false
        return false
      }
      this.getVerifyFlag = true
      sendSms({
        phone: this.phoneNumber,
        scenes: '_REGISTERED_READ_CARD'
      }).then(res => {
        const { code, msg } = res
        if (code === 200) {
          this.showVerifyKeyBoard = true
          let timer = setInterval(() => {
            this.verifyTime = this.verifyTime - 1
            if (this.verifyTime === 0) {
              clearInterval(timer)
              this.getVerifyFlag = false
              this.verifyTime = 60
            }
          }, 1000)
          Toast.clear()
        } else {
          Toast.fail(msg)
        }
      })
    },
    registered () {
      if (this.loginActive) {
        return false
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      if (this.phoneNumber === '请输入手机号' || this.phoneNumber === '') {
        Toast.fail('请输入手机号')
        return false
      }
      if (this.verifyCode === '请输入验证码' || this.verifyCode === '') {
        Toast.fail('请输入验证码')
        return false
      }
      if (!this.readActive) {
        Toast.fail('请阅读《用户协议》和《用户协议》')
        return false
      }
      this.loginActive = true
      registeredReadCard({
        c_user_guid: this.userGuid,
        c_user_phone: this.phoneNumber,
        c_user_name: this.userRealName,
        c_user_sfz: this.idCard,
        auth_code: this.verifyCode,
        scenes: '_REGISTERED_READ_CARD'
      }).then(res => {
        const { code, msg, data } = res
        if (code === 200) {
          this.setUserReadCard(data.c_user_dzz)
          Toast.clear()
          if (this.$route.params.link) {
            this.$router.push(this.$route.params.link)
          } else {
            this.$router.push('/personal')
          }
        } else {
          this.loginActive = false
          Toast.fail(msg)
        }
      })
    },
    bindUserCard () {
      if (this.loginActive) {
        return false
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      if (!this.userReadCardNo) {
        Toast.fail('请输入读者证号/身份证')
        return false
      }
      if (!this.userReadCardPass) {
        Toast.fail('请输入读者证密码')
        return false
      }
      if (!this.readActive) {
        Toast.fail('请阅读《用户协议》和《用户协议》')
        return false
      }
      this.loginActive = true
      bindReadCard({
        c_user_guid: this.userGuid,
        read_card_no: this.userReadCardNo,
        read_card_pass: this.userReadCardPass
      }).then(res => {
        const { code, msg, data } = res
        if (code === 200) {
          this.setUserReadCard(data.c_user_dzz)
          Toast.clear()
          if (this.$route.params.link) {
            this.$router.push(this.$route.params.link)
          } else {
            this.$router.push('/personal')
          }
        } else {
          this.loginActive = false
          Toast.fail(msg)
        }
      })
    },
    ...mapMutations({
      setUserReadCard: 'SET_USER_READ_CARD'
    })
  },
  watch: {
    showPhoneKeyBoard () {
      if (this.showPhoneKeyBoard) {
        if (this.phoneNumber === '请输入手机号') {
          this.phoneNumber = ''
        }
      } else {
        if (this.phoneNumber === '') {
          this.phoneNumber = '请输入手机号'
        }
      }
    },
    showVerifyKeyBoard () {
      if (this.showVerifyKeyBoard) {
        if (this.verifyCode === '请输入验证码') {
          this.verifyCode = ''
        }
      } else {
        if (this.verifyCode === '') {
          this.verifyCode = '请输入验证码'
        }
      }
    },
    showIdCardKeyBoard () {
      if (this.showIdCardKeyBoard) {
        if (this.idCard === '请输入身份证号') {
          this.idCard = ''
        }
      } else {
        if (this.idCard === '') {
          this.idCard = '请输入身份证号'
        }
      }
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  display flex
  justify-content center
  flex-wrap wrap
  width 750px

  .header
    width 690px
    height 164px
    line-height 164px
    font-size 44px
    color rgba(51, 51, 51, 1)

  .phone
    display flex
    align-items center
    width 670px
    padding-left 20px
    height 80px
    border 1px solid rgba(199, 199, 199, 1)
    border-radius 10px
    margin-top 10px
    margin-bottom 10px
    overflow hidden

    .phone-inp
      width 616px
      height 80px
      line-height 80px
      border none
      -webkit-appearance none
      border-radius 0
      font-size 30px
      color: rgba(153, 153, 153, 1)

      .inp
        width 616px
        height 80px
        border: none
        border-radius 0

        &::-webkit-input-placeholder
          color rgba(153, 153, 153, 1)

        &::-moz-placeholder
          color rgba(153, 153, 153, 1)

        &:-moz-placeholder
          color rgba(153, 153, 153, 1)

        &:-ms-input-placeholder
          color rgba(153, 153, 153, 1)

    .destroy
      width 30px
      height 30px
      bg-image('~@/assets/img/login/destory')
      background-size 100% 100%
      background-repeat no-repeat

  .verify
    display flex
    align-items center
    width 670px
    padding-left 20px
    height 80px
    border 1px solid rgba(199, 199, 199, 1)
    border-radius 10px
    margin-top 10px
    margin-bottom 10px
    overflow hidden

    .verify-inp
      width 490px
      height 80px
      line-height 80px
      border none
      -webkit-appearance none
      border-radius 0
      font-size 30px
      color: rgba(153, 153, 153, 1)

    .get-verify
      display flex
      align-items center
      justify-content center
      width 170px
      height 60px
      border-radius 10px
      background rgba(179, 53, 58, 1)

      .get-verify-text
        line-height normal
        font-size 24px
        color rgba(255, 255, 255, 1)

    .get-verify-time
      display flex
      align-items center
      justify-content center
      width 170px
      height 60px
      border-radius 10px
      background rgba(199, 199, 199, 1)

      .get-verify-time-text
        line-height normal
        font-size 24px
        color rgba(255, 255, 255, 1)

  .login-desc
    display flex
    align-items center
    width 690px
    height 26px
    margin-top 20px

    .login-desc-icon
      width 26px
      height 26px
      bg-image('~@/assets/img/login/round')
      background-size 100% 100%
      background-repeat no-repeat

      &.active
        bg-image('~@/assets/img/login/selected')

    .login-desc-text
      padding-left 21px
      font-size 22px
      color rgba(153, 153, 153, 1)
      line-height normal

      .warning
        color rgba(179, 53, 58, 1)

  .login
    display flex
    align-items center
    justify-content center
    width 690px
    height 80px
    margin-top 88px
    border-radius 10px
    background rgba(179, 53, 58, 1)

    &.active
      background rgba(179, 53, 58, .6)

    .login-text
      line-height normal
      font-size 30px
      color rgba(255, 255, 255, 1)
</style>
