<template>
  <div @touchmove.prevent class="login">
    <scroll ref="scroll" class="scroll" :data="data">
      <div>
        <login-content @refresh="refresh"></login-content>
      </div>
    </scroll>
    <router-view></router-view>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import LoginContent from './components/LoginContent'

export default {
  name: 'Login',
  components: {
    Scroll,
    LoginContent
  },
  activated () {
    this.refresh()
  },
  methods: {
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    }
  },
  data () {
    return {
      data: []
    }
  }
}
</script>

<style scoped lang="stylus">
.login
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden
    background rgba(255, 255, 255, 1)
</style>
